.l-header {
  background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0 0 98px;
    position: relative;
    z-index: 100;
    @include mq-max(xl) {
      padding-left: 20px;
    }
    @include mq-max(md) {
      display: block;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 1;
    display: inline-block;
    width: 315px; // 状況によって変更可
    @include mq-max(xl) {
      width: 230px;
      margin-right: 15px;
    }
    @include mq-max(md) {
      margin: 0;
      line-height: 0;
      width: 246px;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    @include mq-max(md) {
      padding: 12px 20px;
      background-color: $white;
      line-height: 0;
    }

    //ハンバーガーメニュー背景
    &:before {
      @include mq-max(md) {
        content: '';
        background-color: $keyColor;
        width: 60px;
        height: 100%;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max(md) {
    display: block;
    position: absolute;
    top: 44%;
    right: 10px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 3;
    span{
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px #fff;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1){
        top: 9px;
      }
      &:nth-child(2){
        // top: 18px;
        top: 16px;
      }
      &:nth-child(3){
        // top: 27px;
        top: 23px;
      }
    }
    &.is-active{
      span{
        &:nth-child(1){
          top: 16px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3){
          top: 16px;
          transform: rotate(45deg);
        }
      }
    }
  }

  //ハンバーガーメニュー下の文字
  &:after {
    @include mq-max(md) {
      content: 'menu';
      text-align: center;
      display: block;
      color: #fff;
      font-size: 11px;
      font-weight: 600;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}
