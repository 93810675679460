.l-navigation {
  background-color: $white;
  @include mq-max(md) {
    display: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }
  &__inner {
    padding: 0;
    display: flex;
    @include mq-max(md) {
      display: block;
    }
  }
  &__item {
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(md) {
      margin-right: 0;
      border-top: 1px solid $gray;
      &:last-child{
        border-bottom: 1px solid $gray;
      }
    }
    a {
      font-size: 15px;
      font-weight: 600;
      @include mq-max(md) {
        background-color: $white;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      display: inline-block;
      padding: 33px 25px;
      @include mq-max(xl) {
        padding: 33px 15px;
      }
      @include mq-max(bp1100) {
        padding: 33px 10px;
      }
      @include mq-max(md) {
        display: block;
        padding: 15px 10px;
      }
      &:hover {
        color: $keyColor;
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a{
        position: relative;
        &:after {
          @include mq-max(md) {
            @include fa('f067');
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
          }
        }
        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
      }
      &:hover {
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max(md) {
            top: auto;
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: $keyColor;
            }
          }
        }
      }
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        @include mq-max(md) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
        li {
          border-bottom: 1px solid $gray;
          @include mq-max(sm) {
            border-bottom: 0;
          }
          &:first-child {
            @include mq-max(md) {
              border-top: 1px solid $gray;
            }
          }
          &:last-child {
            border: none;
          }
          a {
            background-color: $white;
            padding: 10px 15px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            @include mq-max(md) {
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }
          }
        }
      }
      &.is-opened {
        > .l-dropDown {
          @include mq-max(md) {
            opacity: 1;
            display: block;
            > li {
              border-bottom: 1px solid $gray;
            }
            > li > a {
              opacity: 1;
              line-height: 1.5;
              height: 54px;
              padding: 15px 10px 15px 25px;
            }
          }
        }
      }
    }

    //お問い合わせ
    &--contact {
      margin-left: 15px;
      @include mq-max(bp1100) {
        margin-left: 10px;
      }
      @include mq-max(md) {
        margin-left: 0;
      }
      &>a {
        background: $blueGrad;
        padding-left: 42.5px;
        padding-right: 42.5px;
        color: #fff;
        &:hover {
          color: #fff;
        }
        @include mq-max(bp1100) {
          padding-left: 35px;
          padding-right: 35px;
        }
        @include mq-max(md) {
          text-align: center;
          margin-left: 0;
        }
      }
    }

  }
}
