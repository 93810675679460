//コンタクトセクション
.c-contactSection {
  background: url(/inc/image/common/bg_contactSection.jpg)no-repeat center / cover;
  &__inner {
    padding: 55px 0 53px;
    max-width: $contentWidth;
    margin: 0 auto;
    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 55px 20px 53px;
    }
    @include mq-max(xs) {
      padding: 35px 20px 33px;
    }
  }
  &__heading {
    color: #fff;
    font-size: 32px;
    text-align: center;
    margin-bottom: 23px;
    @include mq-max(xs) {
      font-size: 22px;
    }
  }
  &__btn {
    text-align: center;
  }
}

.c-contactTel {
  background-color: #006593;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 15px 20px;
  @include mq-max(sm) {
    display: block;
  }
  &>span {
    color: #fff;
    font-weight: 600;
  }
  &__head {
    font-size: 16px;
    @include mq-max(sm) {
      display: block;
      text-align: center;
    }
    @include mq-max(xs) {
      font-size: 14px;
    }
  }
  &__bottom {
    margin-left: 52px;
    @include mq-max(sm) {
      display: block;
      text-align: center;
      margin: 0;
    }
  }
  &__num {
    font-size: 36px;
    @include mq-max(xs) {
      font-size: 24px;
    }
    a {
      color: inherit;
      &:hover {
        color: #fff;
      }
    }
  }
  &__term {
    font-size: 16px;
    margin-left: 22px;
    @include mq-max(xs) {
      font-size: 13px;
    }
  }
}