.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 2;
  &:last-child {
    margin-bottom: 0;
  }
  // 丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: -0.05em;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
      }
    }
  }
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count);
        position: absolute;
        left: -1.1em;
        color: $keyColor;
      }
    }
    &--brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: -1.5em;
          color: $keyColor;
        }
      }
    }
  }
  &--comment {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em;
      }
    }
    // 番号付き注釈
    &--num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &:before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: -1.5em;
        }
      }
    }
  }

  //困った顔
  &--komatta {
    &>li {
      position: relative;
      font-size: 16px;
      line-height: 1.5;
      padding-left: 20px;
      padding-bottom: 28px;
      color: $keyColor;
      font-weight: 600;
      &:before {
        content: '';
        width: 44px;
        height: 44px;
        background: url(/inc/image/common/ico_komatta.png)no-repeat center / contain;
        display: inline-block;
        position: absolute;
        top: -8px;
        left: -30px;
      }
    }
  }

  //通常リスト
  &--default {
    padding-left: 0;
    &>li {
      font-size: 15px;
      line-height: 2.4;
      font-weight: 300;
      padding-left: 23px;
      padding: 2px 0 2px 18px;
      position: relative;
      &:before {
        content: '';
        background-color: $keyColor;
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        top: 1.1em;
        left: 0;
        transform: rotate(45deg);
      }
    }
  }

  //番号付き
  &--num {
    counter-reset: num;
    padding-left: 0;
    &>li {
      font-size: 15px;
      line-height: 2.4;
      font-weight: 300;
      padding-left: 23px;
      padding: 2px 0 2px 18px;
      position: relative;
      &:before {
        content: counter(num);
        counter-increment: num;
        position: absolute;
        top: 0.2em;
        left: 0;
        font-size: 15px;
        font-weight: 600;
        color: $keyColor;
      }
      &:after {
        content: '';
        background-color: $keyColor;
        width: 4px;
        height: 4px;
        display: inline-block;
        transform: rotate(45deg);
        position: absolute;
        top: 1.3em;
        left: 11px;
      }
    }
  }
}