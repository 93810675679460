//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(sm) {
    width: $full-size;
    padding: 0;
  }
}

//gridlex調整
[class*="grid"] {
  @media screen and (max-width:$contentWidth + $contentPadding) {
//    margin: 0; //不要かどうか観察中
  }
  //headerとfooterに影響が出ないように書くこと
//  [class$="sm-12"] {
//    @include mq-max(sm) {
//      padding-bottom: $contentPadding * 2;
//    }
//  }
//  [class$="xs-12"] {
//    @include mq-max(xs) {
//      padding-bottom: $contentPadding * 2;
//    }
//  }
}
[class*="col-"] {
  .imgFrame {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    width: 100%;
    display: block;
  }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 60px 0;
  // padding-bottom: 120px;//お好みで変更
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 60px 20px;
  }
  @include mq-max(sm) {
    padding: 0 20px 80px;//お好みで変更
  }
  @include mq-max(xs) {
    padding: 0 20px 80px;//お好みで変更
  }
  // &:last-child {
  //   padding-bottom: 0;
  // }
  &__inner {
    margin: 0 auto;
    padding: 0 0 60px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }

  //コンテンツ幅広め
  &--wide {
    max-width: $contentWidth-wide;
  }
  &--medium {
    max-width: $contentWidth-medium;
  }
  &--small {
    max-width: $contentWidth-small;
  }

  //Grid調整
  .grid {
    margin-bottom: -1 * $contentPadding;
  }

}

// コンテンツボックス
.l-contentsBox {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 0 0 60px;
  &:last-child {
    padding-bottom: 0;
  }
  &--wide {
    max-width: $contentWidth-wide;
  }
  &--medium {
    max-width: $contentWidth-medium;
  }
  &--small {
    max-width: $contentWidth-small;
  }
}

//background full width(間隔初期値60px)
@mixin bg_scss($space:60px) {
  padding: $space 0;//お好みで変更
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    margin-bottom: $space;
  }
  @include mq-max(xs) {
    padding: 40px 0 $space;//お好みで変更
    margin-top: -20px;
    margin-bottom: 60px;
  }
  &>.l-section {
    padding: 0 0 $space;
    @include mq-max(sm) {
      padding: 0 20px $space;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
.l-section {
  &--keyColor {
    background-color: $keyColor;
    @include bg_scss();
  }
  &--lightGray {
    background-color: $lightGray;
    @include bg_scss();
  }
  &--triangle {
    position: relative;
    margin-bottom: 42px;
    @include mq-max(xs) {
      margin-bottom: 60px + 21px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 42px 42px 0 42px;
      border-color: $lightGray transparent transparent transparent;
      position: absolute;
      bottom: -42px;
      left: 50%;
      transform: translateX(-50%);
      @include mq-max(xs) {
        border-width: 21px 21px 0 21px;
        bottom: -21px;
      }
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
  margin-bottom: 0 + (-1 * $contentPadding);
  &__wrap {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
    @include mq-max(xs) {
      margin-bottom: 0;
    }
  }
}
