
.c-box {
  background: $white;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 30px 20px;
  margin-bottom: 20px;

}

.c-boxBdr {
  background: #fff;
  border: 1px solid $keyColor;
  border-radius: 10px;
  padding: 30px;
  box-shadow: $box-shadow;
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 0;
  }
}