//footer
.l {
  &-f {
    background-color: #fff;
    &__inner {
      max-width: $contentWidth;
      width: 100%;
      margin: 0 auto;
      padding: 60px 0;
      @include mq-max(md) {
        padding: 60px 20px;
      }
      @include mq-max(xs) {
        padding: 30px 20px;
      }
    }

    &__logo {
      max-width: 315px;
      margin-bottom: 50px;
      @include mq-max(sm) {
        margin: 0 auto 50px;
      }
      @include mq-max(xs) {
        margin-bottom: 60px;
      }
      img {
        width: 100%;
        display: inline-block;
      }
    }

    &__list {
      &>li {
        display: inline-block;
      }
      &--head {
        margin-bottom: 20px;
        @include mq-max(sm) {
          display: flex;
          flex-wrap: wrap;
        }
        @include mq-max(xs) {
          margin-bottom: 40px;
        }
        &>li {
          margin-right: 40px;
          &:last-child {
            margin-right: 0;
          }
          @include mq-max(md) {
            margin-right: 20px;
          }
          @include mq-max(sm) {
            width: 50%;
            margin: 0 0 10px;
          }
          @include mq-max(xs) {
            width: 100%;
            margin: 0;
            border-top: 1px solid $keyColor;
            &:last-child {
              border-bottom: 1px solid $keyColor;
            }
          }
          &>a {
            font-size: 20px;
            font-weight: 600;
            position: relative;
            padding-left: 18px;
            &:before {
              @include fa('f054');
              font-size: 60%;
              position: absolute;
              top: 55%;
              left: 0;
              transform: translateY(-50%);
              font-weight: 600;
              line-height: 1;
              transition: all $transition;
            }
            &:hover {
              &:before {
                left: 8px;
                @include mq-max(xs) {
                  left: 10px;
                }
              }
            }
            @include mq-max(md) {
              font-size: 18px;
            }
            @include mq-max(sm) {
              font-size: 22px;
            }
            @include mq-max(xs) {
              font-size: 20px;
              padding: 15px 0 15px 25px;
              display: block;
              &:before {
                left: 10px;
              }
            }
          }
        }
      }
      &--bottom {
        @include mq-max(sm) {
          display: flex;
          flex-wrap: wrap;
        }
        &>li {
          margin-right: 35px;
          &:last-child {
            margin-right: 0;
          }
          @include mq-max(md) {
            margin-right: 15px;
          }
          @include mq-max(sm) {
            width: 50%;
            margin: 0 0 10px;
          }
          &>a {
            font-size: 15px;
            font-weight: 600;
            position: relative;
            @include mq-max(md) {
              font-size: 14px;
              padding-left: 15px;
            }
            &:before {
              @include mq-max(xs) {
                @include fa('f054');
                position: absolute;
                top: 55%;
                left: 0;
                transform: translateY(-50%);
                line-height: 1;
              }
            }
          }
        }
      }
    }

    &__copyright {
      display: block;
      text-align: center;
      color: #fff;
      background-color: $keyColor;
      font-size: 13px;
      font-weight: 600;
      padding: 10px;
      @include mq-max(xs) {
        padding: 19px 10px;
      }
    }
  }
}