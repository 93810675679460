///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c {
  //h2 default
  &-ttl2 {
    color: $keyColor;
    line-height: 1.5;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 26px;
    @include mq-max(xs) {
      font-size: 22px;
    }
  }

  //h3 default
  &-ttl3 {
    color: $keyColor;
    line-height: 1.5;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
    &--black {
      color: $textColor;
    }
  }

  //h4 default
  &-ttl4 {
    color: $keyColor;
    line-height: 1.5;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    &--black {
      color: $textColor;
    }
  }

  //心電図マーカー
  &-ttl-echo {
    color: $keyColor;
    line-height: 1.5;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 26px;
    @include mq-max(xs) {
      font-size: 22px;
    }
    &>small {
      color: inherit;
      font-size: 20px;
      display: block;
      position: relative;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      @include mq-max(xs) {
        font-size: 18px;
      }
      &:after {
        content: '';
        display: inline-block;
        width: 64px;
        flex: 0 0 64px;
        height: 30px;
        background: url(/inc/image/common/ico_echo.png)no-repeat center / contain;
        margin-left: 36px;
      }
    }
  }
}