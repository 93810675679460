//テーブル
.c-tbl {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th,td {
    border: 1px solid $bdrColor;
    padding: 30px 20px;
    background-color: #fff;
  }

  //デフォルト
  &__block {
    tbody{
      tr{
        @include mq-max(xs){
          &:not(:last-child){
            td{
              border-bottom: none;
            }
          }
        }
        th{
          width: 20%;
          background-color: $lightBlue;
          @include mq-max(xs){
            border-bottom: none !important;
            text-align: center;
            padding: 20px 10px;
          }
        }
        td{
          width: 80%;
        }
        th,td{
          @include mq-max(xs){
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  //商品比較型
  &__compare {
    @include mq-max(xs) {
      width: 100%;
    }
    thead {
      @include mq-max(xs) {
        display: none;
      }
      th {
        background-color: $keyColor;
        &.p_name {
          width: 22.5%;
        }
        &.p_content {
          width: 40%;
        }
        &.deposit_term {
          width: 22.5%;
        }
        &.deposit_price {
          width: 15%;
        }
      }
    }
    tbody {
      tr,td {
        @include mq-max(xs) {
          display: block;
          width: 100%;
          position: relative;
        }
      }
      tr {
        @include mq-max(xs) {
          margin-bottom: 25px;
        }
        &:last-child {
          @include mq-max(xs) {
            margin-bottom: 0;
          }
        }
      }
      td {
        vertical-align: middle;
        font-size: 16px;
        &:last-child {
          @include mq-max(xs) {
            border-bottom: 1px solid $bdrColor;
          }
        }
        @include mq-max(xs) {
          display: block;
          padding: 0;
          font-size: 14px;
          border-bottom: none;
        }
        &:before {
          @include mq-max(xs) {
            content: attr(data-tblName);
            font-weight: 600;
            display: block;
            padding: 10px 20px;
            text-align: center;
            border-bottom: 1px solid $bdrColor;
            background-color: #F8F8F8;
          }
        }
        span {
          @include mq-max(xs) {
            display: block;
            padding: 10px;
          }
        }
      }
      th {
        background-color: #999;
        font-size: 22px;
        @include mq-max(xs) {
          width: 100%;
          display: block;
          background-color: $keyColor;
          color: $white;
          border-bottom: none;
        }
      }
    }
  }

  //スクロール型
  &__scroll {
    @include mq-max(xs) {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid #cecece;
      border-radius: 5px;
      &:before {
        content: "←スライドで表の詳細を確認できます→";
        color: #666;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center;
      }
      table {
        min-width: 640px;
        th, td {
          display: table-cell;
        }
        th {
          white-space: nowrap;
        }
      }
    }
  }

}
