.pageTop{
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 1000;
  font-size: 16px;
  font-weight: 600;
  &:before {
    @include fa('f077');
    line-height: 1;
  }
  &:after {
    content: 'TOPへ戻る';
  }
  &:before,&:after {
    display: block;
    color: $keyColor;
    text-align: center;
    transition: all $transition;
  }
  &:hover {
    color: $keyColor-hover;
    &:before,&:after {
      color: $keyColor-hover;
    }
  }
}