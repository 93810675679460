//タイル

//gridlexと一緒に使う
.c-tile {
  &__inner {
    background-color: #fff;
    padding: 25px;
    box-shadow: $box-shadow;
    display: block;
    transition: all $transition !important;
    &:hover {
      box-shadow: 0px 0px 0px rgba(11, 80, 111, 0);
    }
  }
  &__title {
    font-size: 20px;
    color: $keyColor;
    text-align: center;
    margin-bottom: 0;
  }
}