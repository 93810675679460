//装飾パーツ

.c-diamond {
  padding: 15px 0;
  display: inline-block;
  &--pink {
    .c-diamond__wrap {
      background-color: $accentColor;
    }
  }

  &__wrap {
    background-color: $keyColor;
    width: 76px;
    height: 76px;
    display: inline-block;
    transform: rotate(45deg);
  }
  &__inner {
    position: relative;
    transform: rotate(-45deg);
    width: 100%;
    height: 100%;
    &>small,&>span {
      display: block;
      color: #fff;
      line-height: 1;
      width: 100%;
      text-align: center;
    }
    &>small {
      font-size: 13px;
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
    &>span {
      font-size: 32px;
      font-weight: 600;
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}